import { memo, useCallback, useState } from 'react';
import { cn, GalleryWithProgress, NavArrows } from '@divlab/divanui';

import Section from '@Components/Section';
import CardInView from '@Components/CardInView';
import styles from './CrossSaleSection.module.css';

import type { ProductData } from '@Types/Product';
import type { FC, HTMLAttributes, ReactElement, ReactChild } from 'react';
import type { ProgressOptions } from '@divlab/divanui';

export interface RenderItem {
  product: ProductData;
}

export interface CrossSaleSectionProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  className?: string;
  cnGallery?: string;
  cnGalleryItem?: string;
  title?: ReactChild;
  analyticsTitle: string;
  tabs?: ReactElement;
  text?: string;
  products: ProductData[];
  offset?: number;
  renderItem?: (props: RenderItem) => ReactElement;
  isMainSection?: boolean;
}

const CrossSaleSection: FC<CrossSaleSectionProps> = (props) => {
  const {
    className,
    cnGallery,
    cnGalleryItem,
    title,
    analyticsTitle,
    products,
    text,
    tabs,
    offset = 0,
    isMainSection,
    renderItem,
    ...restProps
  } = props;
  const [slide, setSlide] = useState(0);
  const [track, setTrack] = useState<ProgressOptions>(null);

  const normalizeSlide = useCallback(
    (value: number) => {
      if (value < 0) return 0;
      if (value > products.length) return products.length;

      return value;
    },
    [products.length],
  );

  const handleChangeCurrent = useCallback(({ current }) => {
    setSlide(current);
  }, []);

  const handleChangeProgress = useCallback((opts: ProgressOptions) => {
    setTrack(opts);
  }, []);

  const handlePrev = useCallback(() => {
    setSlide((prev) => normalizeSlide(prev - 1));
  }, [normalizeSlide]);

  const handleNext = useCallback(() => {
    if (track.finished) return;

    setSlide((prev) => normalizeSlide(prev + 1));
  }, [normalizeSlide, track]);

  return (
    <Section
      {...restProps}
      className={cn(styles.section, className)}
      title={title}
      description={text && <div className={styles.text}>{text}</div>}
      isMainSection={isMainSection}
      arrows={
        <NavArrows
          className={cn(styles.arrows, { [styles.visible]: track?.width < 100 })}
          onPrev={handlePrev}
          onNext={handleNext}
        />
      }
      tabs={tabs && <div className={styles.tabs}>{tabs}</div>}
    >
      <div className={styles.wrapperGallery}>
        <GalleryWithProgress
          slideIndex={slide}
          onChangeCurrent={handleChangeCurrent}
          onChangeProgress={handleChangeProgress}
          key={products.length}
          className={cnGallery}
          cnTrack={styles.wrapperProgress}
        >
          {products.map((product, index) => {
            const elem = renderItem({
              product,
            });

            return (
              <div key={product.id} className={cn(styles.galleryItem, cnGalleryItem)}>
                <CardInView
                  listTitle={analyticsTitle}
                  card={product}
                  position={offset + index}
                  cardType='product'
                  id={product.id}
                >
                  {elem}
                </CardInView>
              </div>
            );
          })}
        </GalleryWithProgress>
      </div>
    </Section>
  );
};

export default memo(CrossSaleSection);
